@import 'variables';

// <editor-fold desc="Text">

.text-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// </editor-fold>

// <editor-fold desc="Animations">

.spinner-size(@size: 1.5rem, @color: @color-default-2, @border-width: 2px) {
  display: block;
  margin: 0 auto;
  border-radius: 100%;
  border: @border-width solid @color;
  border-top: @border-width solid @color-primary-3;
  width: @size;
  height: @size;
  animation: cr-spinner-spin .8s linear infinite;
  flex: 0 0 @size; // não deve ter seu tamanho alterado mesmo em containers flex
}

// </editor-fold>

// <editor-fold desc="Grid">

.grid-columns(@child-prefix, @i, @brakpoint, @columns: 12) when (@i > 0) {
  > .@{child-prefix}-@{brakpoint}-@{i} {
    width: percentage(@i / @columns);
  }

  @offset: @i - 1;

  > .@{child-prefix}-@{brakpoint}-offset-@{offset} {
    margin-left: percentage(@offset * (1 / @columns));
  }

  .grid-columns(@child-prefix, @i - 1, @brakpoint);
}

.grid(@child-prefix) {
  > * {
    width: 100%;
  }

  .grid-columns(@child-prefix, 12, xs);
}

.grid-h-spacing(@horizontal-gap) {
  margin-left: -@horizontal-gap;
  margin-right: -@horizontal-gap;

  > * {
    padding: 0 @horizontal-gap;
  }
}

.grid-v-spacing(@vertical-gap) {
  margin-bottom: 2 * -@vertical-gap;

  > * {
    padding-bottom: 2 * @vertical-gap;
  }
}

// </editor-fold>

// <editor-fold desc="Modal">

.modal-size(@width: 600px; @margin-top: 2rem) {
  .cr-modal-dialog {
    @media (@min-screen-xs) {
      width: @width;
      margin: @margin-top auto 2rem;
    }
  }
}

// </editor-fold>

// <editor-fold desc="Buttons">

.btn-variant-states(@variant) {
  @color-3: 'color-@{variant}-3';
  @color-4: 'color-@{variant}-4';
  @color-5: 'color-@{variant}-5';

  background-color: @@color-3;
  border-color: @@color-4;
  color: white;

  &:not([disabled]):not(.btn-disabled) {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: @@color-4;
        border-color: @@color-5;
      }

      a&:hover {
        color: white;
      }
    }

    &:active, &.btn-active {
      background-color: @@color-5;
      border-color: @@color-5;
    }
  }
}

.btn-color-states(@color-1, @color-2, @color-3, @color-text: white, @color-text-hover: white) {
  background-color: @color-1;
  border-color: @color-2;
  color: @color-text;

  &:not([disabled]):not(.btn-disabled) {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: @color-2;
        border-color: @color-3;
      }

      a&:hover {
        color: @color-text-hover;
      }
    }

    &:active, &.btn-active {
      background-color: @color-3;
      border-color: @color-3;
    }
  }
}

// </editor-fold>

// <editor-fold desc="Input">

.input-right-icon() {
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  content: '';
  font-size: 18px;
  pointer-events: none;
}

// </editor-fold>

// <editor-fold desc="Scroll">

.scroll-momentum() {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; // iOS smooth scroll / momentum
}

// </editor-fold>

// <editor-fold desc="Materials">

.paper(@dark-theme-color: @color-default-0) {
  background-color: @color-default-0;

  .dark & {
    background-color: @dark-theme-color;
  }
}

// </editor-fold>

// <editor-fold desc="Input States">

.input-states() {
  border: @input-border-width solid @input-border-color;
  border-radius: .2rem;
  transition: border-color @transition-curve @transition-duration;
  color: @color-default-text-5;
  background-color: @color-input-background;

  &[disabled], &.disabled {
    background-color: @color-default-1;
    border-color: @color-default-2;
    color: @color-default-text-3;
  }

  &.required.error { // todo remover
    border: @input-border-width solid @color-danger-2;
    color: @color-danger-2;
  }

  each(@color-variants, {
    &.input-@{value} {
      @color: 'color-@{value}-3';
      border-color: @@color;
    }
  })

  &.danger {
    border-color: @color-danger-3;

    // todo revisar
    + .input-observation {
      color: @color-danger-4;
    }
  }

  @media (hover: hover)  and (pointer: fine) {
    &:hover:not([disabled]):not(.disabled) {
      border-color: @input-text-border-color-hover;
    }
  }

  &:focus, &:focus-within {
    border-color: @color-default-5;
  }
}

// </editor-fold>

