@import 'variables';

.input-group {
  display: flex;

  .form-group & {
    margin-bottom: 0;
  }

  &:hover {
    > label,
    > span,
    > input,
    > select,
    > textarea {
      border-color: @color-primary-3;
    }
  }

  &:focus-within {
    > label,
    > span {
      border-color: @color-default-5;
    }
  }

  &.input-group-sm {
    > label,
    > span {
      line-height: @input-height-small-xs;

      @media (@min-screen-sm) {
        line-height: @input-height-small-sm;
      }
    }
  }

  &.input-group-lg {
    > label,
    > span {
      line-height: @input-height-large-xs;

      @media (@min-screen-sm) {
        line-height: @input-height-large-sm;
      }
    }
  }

  &.input-group-xl {
    > label,
    > span {
      line-height: @input-height-extra-large-xs;

      @media (@min-screen-sm) {
        line-height: @input-height-extra-large-sm;
      }
    }
  }

  > label,
  > span {
    background-color: @color-default-1;
    border: @input-border-width solid @input-border-color;
    color: @color-default-text-4;
    padding: 0 1rem;
    white-space: nowrap;
    line-height: @input-height-xs;

    @media (@min-screen-sm) {
      line-height: @input-height-sm;
    }

    &:first-child {
      border-top-left-radius: @radius-default;
      border-bottom-left-radius: @radius-default;
    }

    &:last-child {
      border-top-right-radius: @radius-default;
      border-bottom-right-radius: @radius-default;
    }

    &:not(:first-child) {
      border-left-width: 1px;
    }

    &:not(:last-child) {
      border-right-width: 1px;
    }
  }

  > input,
  > select,
  > textarea {
    &:not(:first-child) {
      border-left: none;
    }

    &:not(:last-child) {
      border-right: none;
    }
  }

  > :not(:first-child),
  .cr-select:not(:first-child) select {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  > :not(:last-child),
  .cr-select:not(:last-child) select {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}